import API from '@aws-amplify/api';

export interface Options {
  body?: Record<string, any>;
  headers?: Record<string, string>;
}

type ApiFunction = (
  apiName: string,
  path: string,
  init: Options
) => Promise<any>;

const makeApiCall = async <Response = any>(
  fn: ApiFunction,
  path: string,
  options: Options
): Promise<Response> => {
  const response = await fn('pam-backend', path, options);

  return response as Response;
};

export const get = <Response>(
  path: string,
  options: Options = {}
): Promise<Response> => {
  return makeApiCall<Response>(API.get.bind(API), path, options);
};

export const post = <Response>(
  path: string,
  options: Options = {}
): Promise<Response> => {
  return makeApiCall<Response>(API.post.bind(API), path, options);
};

export const put = <Response>(
  path: string,
  options: Options = {}
): Promise<Response> => {
  return makeApiCall<Response>(API.put.bind(API), path, options);
};

export const del = <Response>(
  path: string,
  options: Options = {}
): Promise<Response> => {
  return makeApiCall<Response>(API.del.bind(API), path, options);
};

export default {
  get,
  post,
  put,
  del,
};
