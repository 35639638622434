import React, { useMemo } from 'react';
import { SWRConfig } from 'swr';

import { useCurrentAuthUser } from 'utils/hooks/auth';

import AppContext from 'Context';

import Loader from 'components/Loader';
import Notifications from 'components/Notifications';

import Routes from 'routes';

import { GlobalStyles } from 'styles/theme';

const App = () => {
  const [status, user] = useCurrentAuthUser();

  const shouldRender =
    status === 'AUTHENTICATED' || status === 'NOT_AUTHENTICATED';

  const context = useMemo(() => {
    return {
      auth: {
        user: user || undefined,
        status,
      },
    };
  }, [status, user]);

  return (
    <>
      <GlobalStyles />
      <Notifications />
      {shouldRender ? (
        <SWRConfig
          value={{ shouldRetryOnError: false, dedupingInterval: 20000 }}
        >
          <AppContext.Provider value={context}>
            <Routes />
          </AppContext.Provider>
        </SWRConfig>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default App;
