import { useState, useCallback, useEffect } from 'react';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

import { safePromise, isEmpty } from 'utils';
import { AuthUser, NotificationType } from 'utils/types';
import { addNotification } from 'utils/notifications';

type AuthOperationStatus = 'loading' | 'success' | 'error';

const useMessageNotification = (
  message?: string,
  status?: AuthOperationStatus
): void => {
  useEffect(() => {
    if (message) {
      let type: NotificationType = 'error';

      if (status === 'loading') {
        type = 'warning';
      }

      if (status === 'success') {
        type = 'success';
      }

      addNotification(message, type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);
};

export const useCurrentAuthUser = (): [AuthStatus, AuthUser | null] => {
  const [status, setStatus] = useState<AuthStatus>('CHECKING_AUTHENTICATION');
  const [user, setUser] = useState<AuthUser | null>(null);

  const getCurrentUser = useCallback(() => {
    const checkAuthAsync = async (): Promise<void> => {
      const [error, currentUser] = await safePromise<AuthUser>(
        Auth.currentAuthenticatedUser()
      );

      if (error) {
        setStatus('NOT_AUTHENTICATED');
        return;
      }

      setStatus('AUTHENTICATED');
      setUser(currentUser);
    };

    checkAuthAsync();
  }, []);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    Hub.listen('auth', () => {
      getCurrentUser();
    });
  }, [getCurrentUser]);

  return [status, user];
};

const verifyContact = async (
  user: AuthUser,
  history: History,
  path = '/'
): Promise<void> => {
  const [, success] = await safePromise(Auth.verifiedContact(user));

  if (success && !isEmpty(success.verified)) {
    history.push(path);
  } else {
    history.push('/login/verify-contact');
  }
};

let tempUser: AuthUser;

const handleChallenge = async (
  user: AuthUser,
  history: History,
  path?: string
): Promise<void> => {
  tempUser = user;

  switch (user.challengeName) {
    case 'NEW_PASSWORD_REQUIRED':
      history.push('/login/new-password', { param: user.challengeParam });
      break;
    case 'MFA_SETUP':
      // TODO: Will be implemented in the future.
      console.error('Not implemented yet', 'MFA_SETUP'); // eslint-disable-line no-console
      break;
    case 'SMS_MFA':
    case 'SOFTWARE_TOKEN_MFA':
      // TODO: Will be implemented in the future.
      console.error('Not implemented yet', 'MFA'); // eslint-disable-line no-console
      break;
    default:
      await verifyContact(user, history, path);
      break;
  }
};

interface AuthOperationState {
  status?: AuthOperationStatus;
  message?: string;
}

type SignInFunction = (email: string, password: string, path?: string) => void;

type AuthHook<T> = [T, AuthOperationStatus | undefined, string | undefined];

export const useSignIn = (): AuthHook<SignInFunction> => {
  const [state, setState] = useState<AuthOperationState>({});
  const history = useHistory();

  useMessageNotification(state.message, state.status);

  const signIn = useCallback(
    (email: string, password: string, path?: string) => {
      const trimmedEmail = email.trim().toLowerCase();

      const signInAsync = async (): Promise<void> => {
        setState({ status: 'loading' });

        const [error, success] = await safePromise(
          Auth.signIn(trimmedEmail, password)
        );

        if (error) {
          switch (error.code) {
            case 'UserNotConfirmedException':
              history.push('/login/confirm-sign-up', { email: trimmedEmail });
              break;
            case 'PasswordResetRequiredException':
              history.push('/login/forgot-password', { email: trimmedEmail });
              break;
            default:
              setState({
                status: 'error',
                message: error.message || 'Please fill in the form.',
              });
              break;
          }

          return;
        }

        if (success) {
          await handleChallenge(success, history, path);
        }
      };

      signInAsync();
    },
    [history]
  );

  return [signIn, state.status, state.message];
};

type NewPasswordFunction = (
  password: string,
  passwordRepeat: string,
  attrs: FormValues
) => void;

export const useNewPassword = (): AuthHook<NewPasswordFunction> => {
  const history = useHistory();
  const [newPasswordState, setState] = useState<AuthOperationState>({});

  useMessageNotification(newPasswordState.message, newPasswordState.status);

  const newPassword = useCallback(
    (password: string, passwordRepeat: string, attrs: FormValues) => {
      const newPasswordAsync = async (): Promise<void> => {
        if (!tempUser) {
          addNotification(
            'Please login first to create a new password.',
            'error'
          );
          history.push('/login');
          return;
        }

        if (password !== passwordRepeat) {
          setState({ status: 'error', message: 'Passwords do not match.' });
          return;
        }

        setState({ status: 'loading' });

        const [error, success] = await safePromise(
          Auth.completeNewPassword(tempUser, password, attrs)
        );

        if (error) {
          if (error.name === 'TypeError') {
            history.push('/login');
            return;
          }

          setState({ status: 'error', message: error.message });
          return;
        }

        if (success) {
          await handleChallenge(success, history, '/login');
        }
      };

      newPasswordAsync();
    },
    [history]
  );

  return [newPassword, newPasswordState.status, newPasswordState.message];
};

type SignOutFunction = () => void;

export const useSignOut = (): AuthHook<SignOutFunction> => {
  const [state, setState] = useState<AuthOperationState>({});

  const signOut = useCallback(() => {
    const asyncSignOut = async (): Promise<void> => {
      setState({ status: 'loading' });

      await safePromise(Auth.signOut());

      window.setTimeout(() => {
        window.location.reload();
      }, 500);
    };

    asyncSignOut();
  }, []);

  return [signOut, state.status, undefined];
};

type ForgotPasswordFunction = (email: string) => void;

export const useForgotPassword = (): AuthHook<ForgotPasswordFunction> => {
  const [state, setState] = useState<AuthOperationState>({});
  const history = useHistory();

  useMessageNotification(state.message, state.status);

  const forgotPassword = useCallback(
    (email: string) => {
      const trimmedEmail = email.trim().toLowerCase();

      const forgotPasswordAsync = async (): Promise<void> => {
        setState({ status: 'loading' });

        const [error, success] = await safePromise(
          Auth.forgotPassword(trimmedEmail)
        );

        if (error) {
          setState({
            status: 'error',
            message: error.message || 'An error occured.',
          });
          return;
        }

        setState({ status: 'success' });

        history.push('/login/change-password', {
          email: trimmedEmail,
          destination: success ? success.CodeDeliveryDetails.Destination : null,
        });
      };

      forgotPasswordAsync();
    },
    [history]
  );

  return [forgotPassword, state.status, state.message];
};

type ChangePasswordFunction = (
  email: string,
  code: string,
  password: string
) => void;
type ResendCodeFunction = (email: string) => void;

export const useChangePassword = (): [
  ChangePasswordFunction,
  ResendCodeFunction,
  AuthOperationStatus | undefined,
  string | undefined
] => {
  const [state, setState] = useState<AuthOperationState>({});
  const [signIn, status, message] = useSignIn();
  const [
    forgotPassword,
    forgotPasswordStatus,
    forgotPasswordMessage,
  ] = useForgotPassword();

  useMessageNotification(state.message, state.status);

  useEffect(() => {
    setState({ status, message });
  }, [status, message]);

  useEffect(() => {
    setState({ status: forgotPasswordStatus, message: forgotPasswordMessage });
  }, [forgotPasswordStatus, forgotPasswordMessage]);

  const changePassword = useCallback(
    (email: string, code: string, password: string) => {
      const trimmedEmail = email.trim().toLowerCase();

      const changePasswordAsync = async (): Promise<void> => {
        setState({ status: 'loading' });

        const [error] = await safePromise(
          Auth.forgotPasswordSubmit(trimmedEmail, code, password)
        );

        if (error) {
          setState({
            status: 'error',
            message: error.message || 'An error occured.',
          });
          return;
        }

        signIn(trimmedEmail, password, '/clients');
      };

      changePasswordAsync();
    },
    [signIn]
  );

  const resendCode = useCallback(
    (email: string) => {
      forgotPassword(email);
    },
    [forgotPassword]
  );

  return [changePassword, resendCode, state.status, state.message];
};

type SignUpFunction = (email: string, password: string, name: string) => void;

export const useSignUp = (): AuthHook<SignUpFunction> => {
  const [state, setState] = useState<AuthOperationState>({});
  const history = useHistory();

  useMessageNotification(state.message, state.status);

  const signUp = useCallback(
    (email: string, password: string, name: string) => {
      const signUpAsync = async (): Promise<void> => {
        setState({ status: 'loading' });

        const trimmedEmail = email.trim().toLowerCase();

        const attributes: Record<string, string | number> = { name };

        const [error] = await safePromise(
          Auth.signUp({
            username: trimmedEmail,
            password,
            attributes,
          })
        );

        if (error) {
          setState({ status: 'error', message: error.message });
          return;
        }

        history.push('/login/confirm-sign-up', {
          email: trimmedEmail,
          password,
        });
      };

      signUpAsync();
    },
    [history]
  );

  return [signUp, state.status, state.message];
};

type ResendSignUpFunction = (email: string) => void;

export const useResendSignUp = (): AuthHook<ResendSignUpFunction> => {
  const [state, setState] = useState<AuthOperationState>({});

  const resendSignUp = useCallback((email: string) => {
    const resendSignUpAsync = async (): Promise<void> => {
      setState({ status: 'loading' });

      const [error] = await safePromise(
        Auth.resendSignUp(email.trim().toLowerCase())
      );

      if (error) {
        setState({ status: 'error', message: error.message });
        return;
      }

      setState({
        status: 'success',
        message: 'A code has been sent to your email address.',
      });
    };

    resendSignUpAsync();
  }, []);

  return [resendSignUp, state.status, state.message];
};

type ConfirmSignUpFunction = (
  email: string,
  code: string,
  password?: string,
  redirectTo?: string
) => void;

export const useConfirmSignUp = (): [
  ConfirmSignUpFunction,
  ResendCodeFunction,
  AuthOperationStatus | undefined,
  string | undefined
] => {
  const [state, setState] = useState<AuthOperationState>({});
  const history = useHistory();
  const [resendSignUp, status, message] = useResendSignUp();
  const [signIn, signInStatus] = useSignIn();

  useMessageNotification(state.message, state.status);

  useEffect(() => {
    setState({ status, message });
  }, [status, message]);

  useEffect(() => {
    if (signInStatus === 'error') {
      history.push('/login');
    }
  }, [signInStatus, history]);

  const confirmSignUp = useCallback(
    (email: string, code: string, password?: string, redirectTo?: string) => {
      const trimmedEmail = email.trim().toLowerCase();

      const confirmSignUpAsync = async (): Promise<void> => {
        setState({ status: 'loading' });

        const [error] = await safePromise(
          Auth.confirmSignUp(trimmedEmail, code)
        );

        if (error) {
          setState({ status: 'error', message: error.message });
          return;
        }

        if (!password) {
          history.push('/login', { email: trimmedEmail });
          return;
        }

        signIn(trimmedEmail, password, redirectTo || '/clients');
      };

      confirmSignUpAsync();
    },
    [history, signIn]
  );

  const resendCode = useCallback(
    (email: string) => {
      resendSignUp(email);
    },
    [resendSignUp]
  );

  return [confirmSignUp, resendCode, state.status, state.message];
};

type SendVerficationEmailFunction = () => void;

export const useSendVerificationEmail = (
  hasCode: boolean
): AuthHook<SendVerficationEmailFunction> => {
  const [state, setState] = useState<AuthOperationState>({});
  const history = useHistory();

  const sendVerificationEmail = useCallback(() => {
    const sendVerificationEmailAsync = async (): Promise<void> => {
      setState({ status: 'loading' });

      const [userError, user] = await safePromise(Auth.currentUserPoolUser());

      if (userError || !user) {
        history.push('/login');
        return;
      }

      const [error] = await safePromise(
        Auth.verifyCurrentUserAttribute('email')
      );

      if (error) {
        setState({ status: 'error', message: error.message });
        return;
      }

      setState({
        status: 'success',
        message: 'Check your email for verification code.',
      });
    };

    sendVerificationEmailAsync();
  }, [history]);

  useEffect(() => {
    if (!hasCode) {
      sendVerificationEmail();
    }
  }, [hasCode, sendVerificationEmail]);

  return [sendVerificationEmail, state.status, state.message];
};

type VerifyUserEmailFunction = (code: string) => void;

export const useVerifyUserEmail = (
  hasCode = false
): [
  VerifyUserEmailFunction,
  SendVerficationEmailFunction,
  AuthOperationStatus | undefined,
  string | undefined
] => {
  const [state, setState] = useState<AuthOperationState>({
    status: 'loading',
    message: 'Sending code to your email.',
  });

  useMessageNotification(state.message, state.status);

  const history = useHistory();
  const [sendVerificationEmail, status, message] = useSendVerificationEmail(
    hasCode
  );

  useEffect(() => {
    setState({ status, message });
  }, [status, message]);

  const verifyUserEmail = useCallback(
    (code: string) => {
      const verifyUserEmailAsync = async (): Promise<void> => {
        setState({ status: 'loading' });

        const [error] = await safePromise(
          Auth.verifyCurrentUserAttributeSubmit('email', code)
        );

        if (error) {
          setState({ status: 'error', message: error.message });
          return;
        }

        history.push('/clients');
      };

      verifyUserEmailAsync();
    },
    [history]
  );

  const resendCode = useCallback(() => {
    sendVerificationEmail();
  }, [sendVerificationEmail]);

  return [verifyUserEmail, resendCode, state.status, state.message];
};
