import { useEffect, useRef, useState, useCallback } from 'react';
import useSWR, { keyInterface, mutate, ConfigInterface } from 'swr';

import { validateEamil, removeItem, upsertItem } from 'utils';
import * as Api from 'utils/api';
import { NotificationItem } from 'utils/types';
import { addNotification, getNotifications } from 'utils/notifications';

const createRootElement = (id: string) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

const addRootElement = (rootElem: Element) => {
  if (document.body && document.body.lastElementChild) {
    document.body.insertBefore(
      rootElem,
      document.body.lastElementChild.nextElementSibling
    );
  }
};

export const usePortal = (id: string) => {
  const rootElemRef: { current: null | HTMLElement } = useRef(null);

  const getRootElemRef = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  };

  useEffect(() => {
    // Look for existing target dom element to append to
    const existingParent = document.querySelector(`#${id}`);
    // Parent is either a new root or the existing dom element
    const parentElem = existingParent || createRootElement(id);

    // If there is no existing DOM element, add a new one.
    if (!existingParent) {
      addRootElement(parentElem);
    }

    // Add the detached element to the parent
    if (rootElemRef.current) {
      parentElem.appendChild(rootElemRef.current);
    }

    return function removeElement() {
      if (rootElemRef.current) {
        rootElemRef.current.remove();
      }
      if (parentElem.childNodes.length === -1) {
        parentElem.remove();
      }
    };
  }, [id]);

  return getRootElemRef();
};

export const usePageVisibility = (): boolean => {
  const [visible, setVisible] = useState(!document.hidden);

  useEffect(() => {
    const onChange = (): void => {
      setVisible(document.visibilityState !== 'hidden');
    };

    document.addEventListener('visibilitychange', onChange);

    return (): void => {
      document.removeEventListener('visibilitychange', onChange);
    };
  }, []);

  return visible;
};

const formValidator = (form: Form, item: ValidatorItem): boolean => {
  if (item.validator) {
    return item.validator(form, item);
  }

  const { minLength, maxLength, value, required, type } = item;

  if (minLength && value.length < minLength) {
    return false;
  }

  if (maxLength && value.length > maxLength) {
    return false;
  }

  if (required) {
    if (type === 'email') {
      return validateEamil(value);
    }

    if (type === 'checkbox' && value !== 'true') {
      return false;
    }

    if (value === '') {
      return false;
    }
  }

  return true;
};

const variablesToFormObject = (
  variables: FormVariable[],
  initialValues: FormValues = {},
  initialForm: Form = {}
): Form => {
  const form: Form = {};

  for (let i = 0; i < variables.length; i += 1) {
    const variable = variables[i];
    const formVariable = initialForm[variable.name];

    let validatorItem;

    if (formVariable) {
      validatorItem = {
        ...variable,
        value: formVariable.value,
      };
    } else {
      const initialValue = initialValues[variable.name];

      validatorItem = {
        ...variable,
        value: initialValue || '',
      };
    }

    const isValid = formValidator({}, validatorItem);

    form[variable.name] = {
      ...validatorItem,
      isValid,
    };
  }

  return form;
};

const isFormValid = (form: Form): boolean => {
  return Object.keys(form).reduce(
    (previousValue: boolean, key) => previousValue && form[key].isValid,
    true
  );
};

export const useForm = (
  variables: FormVariable[],
  initialValues: FormValues = {}
): [Form, OnInputChange, boolean, OnItemChange] => {
  const initialForm = variablesToFormObject(variables, initialValues);

  const [form, setForm] = useState(initialForm);
  const [isValid, setValid] = useState(isFormValid(form));

  useEffect(() => {
    setForm((currentForm) => {
      const newForm = variablesToFormObject(
        variables,
        initialValues,
        currentForm
      );

      return newForm;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  useEffect(() => {
    setValid(isFormValid(form));
  }, [form]);

  const onItemChange: OnItemChange = useCallback((name, item) => {
    setForm((current) => {
      if (current[name] !== undefined) {
        const newForm = { ...current };

        const variable = newForm[name];

        if (typeof item === 'string') {
          variable.value = item;
        }

        variable.isValid = formValidator(newForm, variable);

        return newForm;
      }

      console.error(`Invalid form variable: ${name}`); // eslint-disable-line no-console

      return current;
    });
  }, []);

  const onChange: OnInputChange = useCallback((event) => {
    if (event && event.target) {
      const variableName = event.target.name;
      const isCheckBox =
        event.target instanceof HTMLInputElement &&
        event.target.type === 'checkbox';
      const { value } = event.target;
      let checked = false;

      if (
        event.target instanceof HTMLInputElement &&
        event.target.type === 'checkbox'
      ) {
        checked = event.target.checked;
      }

      setForm((current) => {
        if (current[variableName] !== undefined) {
          const newForm = { ...current };

          const variable = newForm[variableName];

          let newValue;

          if (isCheckBox) {
            newValue = `${checked ? 'true' : 'false'}`;
          } else {
            newValue = value;
          }

          if (variable.case) {
            if (variable.case === 'upper') {
              newValue = newValue.toUpperCase();
            } else if (variable.case === 'lower') {
              newValue = newValue.toLowerCase();
            }
          }

          variable.value = newValue;
          variable.isValid = formValidator(newForm, variable);

          return newForm;
        }

        console.error(`Invalid form variable: ${variableName}`); // eslint-disable-line no-console

        return current;
      });
    } else {
      console.error('Invalid form event'); // eslint-disable-line no-console
    }
  }, []);

  return [form, onChange, isValid, onItemChange];
};

export const useNotifications = () => {
  const { data: notifications } = useSWR<NotificationItem[]>(
    'notifications',
    getNotifications
  );

  return notifications;
};

export type ApiMethod = 'get' | 'post' | 'put' | 'del';

interface ApiOptions {
  method?: ApiMethod;
  request?: Api.Options;
  cache?: ConfigInterface;
}

const extendCacheKey = (cacheKey: string | string[], id: string): string[] => {
  if (typeof cacheKey === 'string') {
    return [cacheKey, id];
  }

  return [...cacheKey, id];
};

export const useApi = <Response extends unknown = any>(
  path: string,
  cacheKey: string | string[] | null,
  options: ApiOptions = {}
): [Response | undefined, RequestError | undefined] => {
  const { data, error } = useSWR<Response, RequestError>(
    cacheKey,
    () => {
      if (path === null) {
        throw Error('Path cannot be null');
      }

      const method = options.method || 'get';

      switch (method) {
        case 'get':
          return Api.get<Response>(path, options.request);
        case 'post':
          return Api.post<Response>(path, options.request);
        case 'put':
          return Api.put<Response>(path, options.request);
        case 'del':
          return Api.del<Response>(path, options.request);
        default:
          throw new Error(`Invalid api method: ${method}`);
      }
    },
    options.cache
  );

  return [data, error];
};

type MutateCallback<Data = any> = (currentValue: Data | undefined) => Data;
type MutateData<Data> = Data | Promise<Data> | MutateCallback<Data>;
type MutateInterface<Data = any> = (
  key: keyInterface,
  data?: Data | Promise<Data> | MutateCallback<Data>,
  shouldRevalidate?: boolean
) => Promise<Data | undefined>;

interface UpdateOptions<Response, CacheType> {
  method?: ApiMethod;
  request?: Api.Options;
  optimisticUpdateData?: MutateData<CacheType>;
  successMessage?: string;
  errorMessage?: string;
  onSuccess?: (response?: Response) => void;
  onFailure?: (error?: RequestError) => void;
  onCacheMutate?: (
    result: {
      response?: Response;
      error?: RequestError;
      currentData: CacheType;
    },
    mutateCache: MutateInterface<Response>
  ) => CacheType;
  remove?: boolean;
}

export type UpdateFn<Response> = (
  path: string,
  cacheKey: string | string[],
  options?: UpdateOptions<Response, Response[]>
) => void;

export const useApiUpdate = <Response extends { id: string }>(): [
  boolean,
  UpdateFn<Response>
] => {
  const [isLoading, setLoading] = useState(false);

  const updateFn: UpdateFn<Response> = useCallback(
    (path, cacheKey, options = {}) => {
      const isOptimistic = options.optimisticUpdateData !== undefined;

      const mutateCallback = async (
        currentData: Response[] | undefined
      ): Promise<Response[]> => {
        if (!isOptimistic) {
          setLoading(true);
        }

        const method = options.method || 'post';

        let response: Response | undefined;
        let error: RequestError | undefined;

        try {
          switch (method) {
            case 'get':
              response = await Api.get<Response>(path, options.request);
              break;
            case 'post':
              response = await Api.post<Response>(path, options.request);
              break;
            case 'put':
              response = await Api.put<Response>(path, options.request);
              break;
            case 'del':
              response = await Api.del<Response>(path, options.request);
              break;
            default:
              throw new Error(`Invalid api method: ${method}`);
          }
        } catch (exception) {
          error = (exception || {}) as RequestError;

          addNotification(
            exception?.response?.data?.message ||
              options.errorMessage ||
              'Request Failed.',
            'error'
          );
        }

        if (!isOptimistic) {
          setLoading(false);
        }

        let newData: Response[];

        if (options.onCacheMutate) {
          newData = options.onCacheMutate(
            { response, error, currentData: currentData || [] },
            mutate
          );
        } else if (response) {
          mutate(extendCacheKey(cacheKey, response.id), response, false);

          if (
            options.remove === true ||
            (options.remove === undefined && method === 'del')
          ) {
            newData = removeItem(response, currentData || []);
          } else {
            newData = upsertItem(response, currentData || []);
          }
        } else {
          newData = currentData || [];
        }

        if (!isOptimistic) {
          const isSuccess = error === undefined;

          if (isSuccess) {
            options.onSuccess?.(response);
          } else {
            options.onFailure?.(error);
          }
        }

        return newData;
      };

      if (isOptimistic) {
        mutate(cacheKey, options.optimisticUpdateData, false);

        options.onSuccess?.();
      }

      mutate(cacheKey, mutateCallback, false);
    },
    []
  );

  return [isLoading, updateFn];
};

export const useCacheMutate = () => {
  return useCallback(
    <T extends { id: string }>(
      cacheKey: string | string[],
      item: T | T[],
      remove: boolean = false
    ) => {
      if (!remove) {
        if (Array.isArray(item)) {
          item.forEach((i) => mutate(extendCacheKey(cacheKey, i.id), i, false));
        } else {
          mutate(extendCacheKey(cacheKey, item.id), item, false);
        }
      }

      mutate(
        cacheKey,
        (items: T[]) => {
          if (remove) {
            return removeItem(item, items);
          } else {
            return upsertItem(item, items);
          }
        },
        false
      );
    },
    []
  );
};

export const useDebounce = (
  fn: Function,
  delay: number
): [Function, () => void] => {
  const timeout = useRef<number | undefined>();

  useEffect(
    () => (): void => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    },
    []
  );

  const cancel = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
  }, []);

  const debouncedFn = useCallback(
    (...args: any[]) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = window.setTimeout(() => {
        fn(...args);
        timeout.current = undefined;
      }, delay);
    },
    [delay, fn]
  );

  return [debouncedFn, cancel];
};

/* Use this function if you don't know what you are doing. 
A more simple version. */
export function useSimpleDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
    
  useEffect(() => {
    const timer = window.setTimeout(() => setDebouncedValue(value), delay || 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useCurrentUser = (): User | undefined => {
  const [user] = useApi<User>('current-user', 'current-user', {
    cache: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 300000,
    },
  });

  return user;
};

export const useCountries = (): Country[] | undefined => {
  const [countries] = useApi<Country[]>('country', 'country', {
    cache: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 300000,
    },
  });

  return countries;
};

export const useLanguages = (): Language[] | undefined => {
  const [languages] = useApi<Language[]>('language', 'language', {
    cache: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 300000,
    },
  });

  return languages;
};

export const useTwitterAdAccounts = (
  shouldFetch = true
): TwitterAdAccount[] | undefined => {
  const [adAccounts] = useApi<TwitterAdAccount[]>(
    'twitter/ad-account',
    shouldFetch ? 'twitter-ad-account' : null,
    {
      cache: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: 300000,
      },
    }
  );

  return adAccounts;
};

export const useTwitterPromotableUsers = (
  adAccountId?: string
): TwitterPromotableUser[] | undefined => {
  const [promotableUsers] = useApi<TwitterPromotableUser[]>(
    `twitter/ad-account/${adAccountId}/promotable-user`,
    adAccountId ? ['twitter-promotable-user', adAccountId] : null,
    {
      cache: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: 300000,
      },
    }
  );

  return promotableUsers;
};

export const useTwitterFundingInstruments = (
  adAccountId?: string
): TwitterFundingInstrument[] | undefined => {
  const [fundingInstruments] = useApi<TwitterFundingInstrument[]>(
    `twitter/ad-account/${adAccountId}/funding-instrument`,
    adAccountId ? ['twitter-funding-instrument', adAccountId] : null,
    {
      cache: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: 300000,
      },
    }
  );

  return fundingInstruments;
};

export const useCountryGroups = (
  clientId: string,
  suspense = false
): CountryGroup[] | undefined => {
  let cacheOptions;

  if (suspense) {
    cacheOptions = { suspense: true };
  } else {
    cacheOptions = {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 300000,
    };
  }

  const [countryGroups] = useApi<CountryGroup[]>(
    `country-group?client=${clientId}`,
    ['country-group', clientId],
    { cache: cacheOptions }
  );

  return countryGroups;
};
