import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import AppContext from 'Context';

interface Props extends RouteProps {
  type?: 'private' | 'public';
}

const CustomRoute = (props: Props) => {
  const { type } = props;
  const { auth } = useContext(AppContext);

  const isAuthenticated = auth.status === 'AUTHENTICATED';

  if (type === 'private' && !isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (type === 'public' && isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Route {...props} />
  );
};

export default CustomRoute;
