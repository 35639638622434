import { createContext } from 'react';
import { AuthUser } from 'utils/types';

interface AppContext {
  auth: {
    status: AuthStatus;
    user?: AuthUser;
  };
}

const AppContext = createContext<AppContext>({
  auth: { status: 'INITIAL' },
});

export default AppContext;
